import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTransition, animated } from "react-spring";

const useStyles = makeStyles((theme) => ({
  animationItem: {
    width: "100%",
    textAlign: ({ direction }: TransitionContainerProps) =>
      direction === "end" ? "right" : "left",
  },
  content: {
    display: "flex",
    height: "100%",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: ({ direction }: TransitionContainerProps) =>
      `flex-${direction}`,
    justifyContent: ({ hDirection }: TransitionContainerProps) =>
      hDirection ? `flex-${hDirection}` : "center",
    zIndex: 10,
    [theme.breakpoints.down("sm")]: {
      bottom: 0,
      top: "auto",
      transform: "none",
    },
  },
}));

type TransitionContainerProps = {
  children: React.ReactNode;
  direction: "start" | "end";
  hDirection?: "start" | "end";
};

export const TransitionContainer = (props: TransitionContainerProps) => {
  const { children, direction } = props;
  const classes = useStyles(props);
  const data = React.Children.toArray(children);
  const transition = useTransition(data, (items: any) => items.key, {
    from: {
      opacity: 0,
      transform: `translateX(${direction === "start" ? "50%" : "-50%"})`,
    },
    enter: { opacity: 1, transform: "translateX(0)" },
    leave: { opacity: 0, transform: "translateX(-50%)" },
    trail: 100,
  });
  return (
    <div className={classes.content}>
      {transition.map(({ item, props, key }) => (
        <animated.div className={classes.animationItem} key={key} style={props}>
          {item}
        </animated.div>
      ))}
    </div>
  );
};
