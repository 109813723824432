import React, { useState, ChangeEvent } from "react";
import Layout from "../components/layout";
import PageTitle from "../components/PageTitle";
import { TransitionContainer } from "../components/TransitionContainer";
import { Typography, Container, Box, Grid, Tabs, Tab, Select, MenuItem, Card, CardContent } from "@material-ui/core";
import { graphql } from "gatsby";

type IndexProps = {
  data: any;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default ({ data }: IndexProps) => {
  const [value, setValue] = useState(0);
  const [bgImage, setBgImage] = useState(data.image1.childImageSharp.fluid);

  const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 6) {
      setBgImage(data.image2.childImageSharp.fluid);
    } else if (newValue === 2) {
      setBgImage(data.image3.childImageSharp.fluid);
    } else if (newValue === 3) {
      setBgImage(data.image4.childImageSharp.fluid);
    } else if (newValue === 5) {
      setBgImage(data.image5.childImageSharp.fluid);
    } else {
      setBgImage(data.image1.childImageSharp.fluid);
    }
    setValue(newValue);
  };

  return (
    <Layout>
      <PageTitle fluidImage={bgImage} height="auto">
        <TransitionContainer direction="start" hDirection="end">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              marginTop: "150px",
            }}
          >
            <div>
              <Typography
                variant="h3"
                component="h1"
                paragraph
                style={{ marginTop: 80 }}
              >
                Leistungen
              </Typography>
              <Typography paragraph>
                Als Steuerberater sind wir Angehörige eines freien Berufs und
                Organ der Steuerrechtspflege. Durch die gesetzlich geschützte
                berufliche Verschwiegenheit und die detaillierte Kenntnis der
                wirtschaftlichen und persönlichen Verhältnisse tragen wir ein
                überaus hohes Maß an Verantwortung und haben eine besondere
                Vertrauensstellung.
              </Typography>
              <Typography paragraph>
                Ich begleite Sie als unabhängiger und kompetenter Ratgeber bei
                steuerlichen und wirtschaftlichen Fragestellungen mit dem Ziel,
                ihre Interessen optimal zu vertreten sowie deren
                wirtschaftlichen Erfolg zu fördern und zu sichern. Ich erkenne
                steuerliche Wahlrechte aus langjähriger Praxis und schöpfe
                den maximalen Nutzen für sie aus und informiere mich stets über
                aktuelle Veränderungen.
              </Typography>
            </div>
            <Tabs
              value={value}
              onChange={handleTabsChange}
              aria-label="basic tabs example"
              style={{ marginTop: 100 }}
              scrollButtons="auto"
              variant="scrollable"
            >
              <Tab label="Gestaltung" />
              <Tab label="Durchsetzung" />
              <Tab label="Selbstanzeigen" />
              <Tab label="Erbschaft & Schenkungen" />
              <Tab label="Transaktionen" />
              <Tab label="Immobilien" />
              <Tab label="Honorar" />
            </Tabs>
          </div>
        </TransitionContainer>
      </PageTitle>
      <Container>
        <Box paddingTop={10} paddingBottom={10}>
          <TabPanel value={value} index={0}>
            <Grid container spacing={10}>
              <Grid item xs={12} md={6}>
                <Box marginBottom={6}>
                  <Typography variant="h4" component="h2" paragraph>
                    Gestaltung
                  </Typography>
                  <Typography paragraph>
                    Betriebliche oder private Grundsatzentscheidungen sind per
                    se von besonderer Tragweite, deren Bedeutung unter
                    Berücksichtigung steuerlicher Aspekte häufig noch zunimmt.
                  </Typography>
                  <Typography paragraph>
                    Bei der Gestaltung Ihrer Steuer- und Finanzstrukturen geht
                    es um die Prüfung des Status quo, um das Identifizieren
                    von Schwachstellen und um die Realisierung kluger und
                    weitsichtiger Maßnahmen mit dem Ziel der Optimierung.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container spacing={10}>
              <Grid item xs={12} md={6}>
                <Box marginBottom={6}>
                  <Typography variant="h4" component="h2" paragraph>
                    Durchsetzung
                  </Typography>
                  <Typography paragraph>
                    In diesem Bereich werden Strategien erarbeitet um sie bei schwierigen
                    steuerlichen Sachverhalten zu unterstützen und ihre Vertretung
                    gegenüber Behörden und Gerichten erfolgreich zu gestalten.

                    I.e.

                    <ul>
                      <li>Einspruchsverfahren</li>
                      <li>außergerichtliche Rechtsbehelfsverfahren allerart</li>
                      <li>Klageverfahren </li>
                      <li>
                        sowie in steuerstrafrechtlichen Fragen
                      </li>
                    </ul>
                    Die Abwehrstrategien folgen jahrelangen Erfahrungen und
                    dem Ziel, jede noch so herausfordernde Situation in ihrem
                    Sinne professionell zu meistern.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Grid container spacing={10}>
              <Grid item xs={12} md={6}>
                <Box marginBottom={6}>
                  <Typography variant="h4" component="h2" paragraph>
                    Selbstanzeigen
                  </Typography>
                  <Typography paragraph>
                    Was ist eine Selbstanzeige? Mit einfachen Worten ausgedrückt, ist eine Selbstanzeige
                    eine Möglichkeit bei einer Steuerhinterziehung, sich Straffreiheit zu verschaffen.
                    Der Gesetzgeber behält sich so die Option offen, unentdeckt gebliebene Steuerquellen
                    zu vereinnahmen und baut daher dem Steuersünder eine goldene Brücke zur Straffreiheit.
                  </Typography>
                  <Typography paragraph>
                    Dieser Vorteil greift gleichwohl nur, wenn die Selbstanzeige alle formalen Voraussetzungen
                    erfüllt, denn andernfalls läuft man in Gefahr, aufgrund einer nicht wirksamen Selbstanzeige, trotz Selbstanzeige
                    bestraft zu werden.
                  </Typography>
                  <Typography paragraph>
                    Mit Langjähriger Kompetenz verschaffe ich uns einen genauen
                    Überblick über ihre Situation und helfe bei der steuerlichen Aufklärungspflicht.
                    Bei ausländischen Kapitalvermögen übernehme ich gerne die gesamte Kommunikation
                    mit dem ausländischen Kreditinstitut und sorge dafür, dass die für eine Selbstanzeige
                    benötigten steuerrelevanten Unterlagen, vollständig und sicher für die Materiallieferung ankommen.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Grid container spacing={10}>
              <Grid item xs={12} md={6}>
                <Box marginBottom={6}>
                  <Typography variant="h4" component="h2" paragraph>
                    Erbschaft & Schenkungen
                  </Typography>
                  <Typography paragraph>
                    Ich kümmere mich nicht nur um die Steuererklärung, sondern berate aktiv hinsichtlich
                    ihrer Gestaltungsmöglichkeiten beim Vererben und Verschenken.
                  </Typography>
                  <Typography paragraph>
                    Erbfälle sind herausfordernd, ob rechtlich, steuerlich, wirtschaftlich als auch emotional.
                  </Typography>
                  <Typography paragraph>
                    Gerade wenn es um große Werte wie Betriebsvermögen und Immobilien geht,
                    ist die steuerliche und rechtliche Begleitung umso wichtiger.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Grid container spacing={10}>
              <Grid item xs={12} md={6}>
                <Box marginBottom={6}>
                  <Typography variant="h4" component="h2" paragraph>
                    Transaktionen
                  </Typography>
                  <Typography paragraph>
                    Bei Transaktionen benötigt man steuerliche Beratung.
                  </Typography>
                  <Typography paragraph>
                    Egal ob zum Beispiel Share oder Asset Deal,
                    insbesondere im Hinblick auf die Vermeidung oder
                    Minimierung von Steuern (Ertragsteuer, Grunderwerbsteuer, etc.)
                  </Typography>
                  <Typography paragraph>
                    Die Leistungen stellen sich wie folgt dar.
                    <ul>
                      <li>Gestaltung steueroptimaler Exitlösungen</li>
                      <li>Steuerliche Konzeption </li>
                      <li>Steuergutachten</li>
                      <li>
                        Tax Due Diligence
                      </li>
                      <li>Prüfung von Investitionsvorhaben</li>
                      <li>Wirtschaftlichkeits- und Prognoserechnungen</li>
                    </ul>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Grid container spacing={10}>
              <Grid item xs={12} md={6}>
                <Box marginBottom={6}>
                  <Typography variant="h4" component="h2" paragraph>
                    Immobilien
                  </Typography>
                  <Typography paragraph>
                    Immobilien stellen häufig einen wesentlichen Teil des Vermögens dar.

                    Umso wertvoller ist es daher, sich bezüglich steuerlicher
                    Optimierungsmöglichkeiten von einem Experten beraten zu lassen,
                    welcher sich mit den verschiedenen Thematiken rund um die Immobilie auskennt.
                  </Typography>
                  <Typography paragraph>
                    Ich berate Sie kompetent und zuverlässig, u. a.
                    hinsichtlich folgender Themen:
                    <ul>
                      <li>Erwerb und Verkauf</li>
                      <li>Vermietung und Verpachtung</li>
                      <li>Finanzierung</li>
                      <li>Spekulationsfrist</li>
                      <li>Erbschafts- und Schenkungsteuer</li>
                      <li>
                        Erstellung von Steuererklärungen und gesonderten
                        Feststellungen
                      </li>
                      <li>Grundsteuer</li>
                    </ul>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={6}>
            <Grid container spacing={10}>
              <Grid item xs={12} md={6}>
                <Box marginBottom={6}>
                  <Typography variant="h4" component="h2" paragraph>
                    Honorar
                  </Typography>
                  <Typography paragraph>
                    Die Berechnung der Gebühren für Steuerberater beruht auf 
                    der Steuerberatervergütungsverordnung (StBVV). Die Vergütungen 
                    werden i.d.R. als Wertgebühr oder als Zeitgebühr erhoben.
                  </Typography>
                  <Typography paragraph>
                    Die Gebühren für Steuerberater richten sich nach der
                    Bedeutung, dem Umfang und der Schwierigkeit der jeweiligen
                    Aufgabe.
                  </Typography>
                  <Typography paragraph>
                    Zweck der Vergütungsverordnung ist, sowohl im Interesse der
                    Auftraggeber als auch im Interesse der Steuerberater
                    angemessene Gebühren festzusetzen und durch Schaffung klarer
                    Verhältnisse Auseinandersetzungen vermeiden zu helfen.
                  </Typography>
                  <Typography paragraph>
                    Es existieren Maximalgebühren, jedoch auch Mindestgebühren.
                    Im Regelfall ist bei der Findung der angemessenen Gebühr von
                    der Mittelgebühr auszugehen.
                  </Typography>
                  <Typography paragraph>
                    Davon abweichend können Steuerberater höhere Gebühren als in
                    der StBVV vorgesehen vereinbaren, wenn dies schriftlich mit
                    dem Auftraggeber vereinbart wurde.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "chess.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    image2: file(relativePath: { eq: "honorar.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    image3: file(relativePath: { eq: "Z-swiss.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

    image4: file(relativePath: { eq: "Z-erbschaft.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

    image5: file(relativePath: { eq: "Z-Immo2.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
