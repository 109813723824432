import React from "react";
import BackgroundImage from "gatsby-background-image";
import { makeStyles, Container } from "@material-ui/core";

type PageTitleProps = {
  fluidImage?: any;
  children?: React.ReactNode;
  height?: string;
};

const useStyles = makeStyles((theme) => ({
  root: (props: PageTitleProps) => ({
    height: props.height ? props.height : 600,
    backgroundPosition: "bottom bottom",
  }),
  shadow: {
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(45deg, rgba(248,80,50,0) 0%, rgba(0,0,0,.8) 100%)",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      background:
        "linear-gradient(180deg, rgba(248,80,50,0) 0%, rgba(0,0,0,.8) 100%)",
    },
  },
  container: {
    height: "100%",
  },
}));

const PageTitle = ({ fluidImage, children, height }: PageTitleProps) => {
  const classes = useStyles({ height });
  return (
    <>
      {fluidImage && (
        <BackgroundImage className={classes.root} fluid={fluidImage}>
          <div className={classes.shadow}>
            <Container className={classes.container}>{children}</Container>
          </div>
        </BackgroundImage>
      )}
      {!fluidImage && (
        <div className={classes.root}>
          <Container className={classes.container}>{children}</Container>
        </div>
      )}
    </>
  );
};

export default PageTitle;
